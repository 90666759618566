import { useEffect, useState } from "react";
import SingleCourse from "../../../../MainComponents/SingleCourse";
import SingleChapter from "../../../../MainComponents/SingleChapter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useAuthState } from "../../../../MainComponents/GlobalContext.jsx";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { Loader } from "../../../../MainComponents";
import { Input } from "antd";
import {
  DndContext,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

const SortableItem = ({ id, children }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </div>
  );
};

function AdminLectures() {
  const navigate = useNavigate();
  const AuthState = useAuthState();
  const [academiclevel, setAcademiclevel] = useState(null);
  const [academicType, setAcademicType] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [reFetch, setReFetch] = useState(false);
  const [submitPirority, setSubmitPirority] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [allLecturesData, allLecturesErrors, allLecturesloading] = useAxios(
    `${process.env.REACT_APP_ADMIN_LECTURES_STAGE_API}${academiclevel}`,
    "GET",
    academiclevel !== null,
    academiclevel | reFetch
  );
  const [prioritySuccess, priorityErrors, priorityloading] = useAxios(
    process.env.REACT_APP_ADMIN_CHAPTER_EDIT_PIORIRTY,
    "POST",
    submitPirority.flag,
    submitPirority.dependency,
    submitPirority.data,
    true
  );

  const [lectures, setLectures] = useState([]);

  useEffect(() => {
    if (allLecturesData?.data) {
      setLectures(allLecturesData.data);
    }
  }, [allLecturesData]);

  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      distance: 10,
    },
  });
  const touchSensor = useSensor(TouchSensor, {
    // Press delay of 250ms, with tolerance of 5px of movement
    activationConstraint: {
      delay: 250,
      tolerance: 5,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (!active || !over) return; // تأكد إنهم مش undefined

    if (active.id !== over.id) {
      setLectures((lectures) => {
        const clonedLectures = [...lectures]; // تأكد إنك مش بتعدل على الأصل
        const oldIndex = clonedLectures.findIndex(
          (lecture) => lecture.key === active.id
        );
        const newIndex = clonedLectures.findIndex(
          (lecture) => lecture.key === over.id
        );

        if (oldIndex === -1 || newIndex === -1) return clonedLectures;

        const newLectures = arrayMove(clonedLectures, oldIndex, newIndex);

        const updatedLectures = newLectures.map((lecture, index) => ({
          ...lecture,
          priority_num: index + 1,
        }));

        updatePriorities(updatedLectures);

        return updatedLectures;
      });
    }
  };

  useEffect(() => {
    if (prioritySuccess) {
      setSubmitPirority({
        flag: "",
        dependency: false,
        data: {},
      });
    }
  }, [prioritySuccess]);
  const updatePriorities = (updatedLectures) => {
    setSubmitPirority({
      flag: "priority",
      dependency: !submitPirority.dependency,
      data: {
        chapters: updatedLectures.map((lecture) => ({
          id: lecture.key,
          priority_num: lecture.priority_num,
        })),
      },
    });
  };

  const filteredLectures = lectures?.filter((card) => {
    const lowerSearchInput = searchInput.toLowerCase();
    return (
      card?.name?.toLowerCase().includes(lowerSearchInput) ||
      card?.title?.toLowerCase().includes(lowerSearchInput)
    );
  });

  return (
    <section
      className={`h-full  w-full  px-20 py-20  dark:bg-dark dark:text-light `}
    >
      <HelmetTags title="Lectures | Mr Ahmed Sabry"></HelmetTags>
      <div className="mb-32 flex flex-col items-end gap-20">
        <h2 className="w-full text-4xl font-bold md:text-center">Lectures</h2>
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some(
            (permission) => permission.name === "lecture_add"
          )) && (
          <div className="flex w-full gap-10 md:justify-evenly">
            <button
              onClick={() => navigate("/admin/lectures/add-lecture")}
              className="mr-4 rounded-xl border-2 border-secondary order-2 bg-light px-2 py-1 font-semibold text-secondary transition-all duration-300 hover:bg-secondary hover:text-light active:scale-90"
            >
              <FontAwesomeIcon className="mr-4" icon={faPlus} />
              Add lecture
            </button>
            <button
              onClick={() => navigate("/admin/lectures/add-chapter")}
              className="mr-4 rounded-xl border-2 border-secondary order-1 bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary active:scale-90"
            >
              <FontAwesomeIcon className="mr-4" icon={faPlus} />
              Add a chapter
            </button>
          </div>
        )}

        <div className="flex w-full gap-10 md:justify-center sm:flex-col-reverse sm:items-center">
          <button
            onClick={() => {
              setAcademicType("national");
              setAcademiclevel("");
            }}
            className={`min-w-[200px] rounded-xl border-2 border-secondary p-4 font-semibold transition-all duration-300 hover:bg-light hover:text-secondary active:scale-90 ${
              academicType === "national" && "bg-secondary text-light"
            }`}
          >
            National
          </button>

          <button
            onClick={() => {
              setAcademicType("ig");
              setAcademiclevel("");
            }}
            className={`min-w-[200px] rounded-xl border-2 border-secondary p-4 font-semibold transition-all duration-300 hover:bg-light hover:text-secondary active:scale-90 ${
              academicType === "ig" && "bg-secondary text-light"
            }`}
          >
            IG
          </button>
        </div>
        {academicType === "national" && (
          <div className="flex w-full gap-10 md:justify-center md:flex-col sm:items-center">
            <button
              onClick={() => {
                setAcademiclevel("22");
              }}
              className={`rounded-xl border-2 border-secondary p-4 font-semibold transition-all duration-300 hover:bg-light hover:text-secondary active:scale-90 ${
                academiclevel === "22" && "bg-secondary text-light"
              }`}
            >
              Second preparatory
            </button>
            <button
              onClick={() => {
                setAcademiclevel("33");
              }}
              className={`rounded-xl border-2 border-secondary p-4 font-semibold transition-all duration-300 hover:bg-light hover:text-secondary active:scale-90 ${
                academiclevel === "33" && "bg-secondary text-light"
              }`}
            >
              Third Preparatory
            </button>

            <button
              onClick={() => {
                setAcademiclevel("1");
              }}
              className={`rounded-xl border-2 border-secondary p-4 font-semibold transition-all duration-300 hover:bg-light hover:text-secondary active:scale-90 ${
                academiclevel === "1" && "bg-secondary text-light"
              }`}
            >
              First Secondary
            </button>

            <button
              onClick={() => {
                setAcademiclevel("2");
              }}
              className={`rounded-xl border-2 border-secondary p-4 font-semibold transition-all duration-300 hover:bg-light hover:text-secondary active:scale-90 ${
                academiclevel === "2" && "bg-secondary text-light"
              }`}
            >
              Second Secondary
            </button>

            <button
              onClick={() => {
                setAcademiclevel("3");
              }}
              className={`rounded-xl border-2 border-secondary p-4 font-semibold transition-all duration-300 hover:bg-light hover:text-secondary active:scale-90 ${
                academiclevel === "3" && "bg-secondary text-light"
              }`}
            >
              Third Secondary
            </button>
          </div>
        )}
        {academicType === "ig" && (
          <div className="flex w-full gap-10 md:justify-center md:flex-col sm:items-center">
            <button
              onClick={() => {
                setAcademiclevel("9");
              }}
              className={`rounded-xl border-2 border-secondary p-4 font-semibold transition-all duration-300 hover:bg-light hover:text-secondary active:scale-90 ${
                academiclevel === "9" && "bg-secondary text-light"
              }`}
            >
              Nine Year
            </button>
            <button
              onClick={() => {
                setAcademiclevel("10");
              }}
              className={`rounded-xl border-2 border-secondary p-4 font-semibold transition-all duration-300 hover:bg-light hover:text-secondary active:scale-90 ${
                academiclevel === "10" && "bg-secondary text-light"
              }`}
            >
              Ten Year
            </button>
          </div>
        )}

        {academicType && academiclevel && (
          <Input
            className="signin-inputs search-table-placeholder h-10 pl-4 !w-1/2 !md:w-full mr-auto"
            autoFocus
            placeholder={`Search In The Lectures`}
            value={searchInput}
            onChange={(e) => {
              setSearchInput(e.target.value);
            }}
          />
        )}
      </div>

      {allLecturesloading ? (
        <Loader />
      ) : (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={filteredLectures
              .filter((lecture) => lecture.is_chapter === 1)
              .map((lecture) => lecture.key)}
            strategy={verticalListSortingStrategy}
          >
            <div className="lectures grid-auto-fit">
              {filteredLectures?.length === 0 ? (
                <p>No lectures</p>
              ) : (
                filteredLectures?.map((Card) => {
                  if (Card.is_chapter) {
                    return (
                      <SortableItem key={Card.key} id={Card.key}>
                        <SingleChapter
                          reFetch={reFetch}
                          setReFetch={setReFetch}
                          admin
                          chapter={Card}
                        />
                      </SortableItem>
                    );
                  } else {
                    return (
                      <SortableItem key={Card.key} id={Card.key}>
                        <SingleCourse
                          reFetch={reFetch}
                          setReFetch={setReFetch}
                          admin
                          lecture={Card}
                        />
                      </SortableItem>
                    );
                  }
                })
              )}
            </div>
          </SortableContext>
        </DndContext>
      )}
    </section>
  );
}

export default AdminLectures;
