import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import useAxios from "../../../../MainComponents/Hooks/useAxios";

export const AdminFilesAddFile = ({ setReFetch }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddEditMaterial": {
        return {
          ...state,
          submitAddFiles: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddFiles: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  //!--------- add edit Material -------

  const [AddFilesSuccess, AddFilesErrors, submitLoading] = useAxios(
    process.env.REACT_APP_ADMIN_ADD_FILE_API,
    "POST",
    state.submitAddFiles.flag,
    state.submitAddFiles.dependency,
    state.submitAddFiles.data,
    true
  );

  //!--------- get the Material info for editing -------

  useEffect(() => {
    //!---Add actions ----
    if (AddFilesSuccess) {
      reset();
      setReFetch((refetch) => !refetch);
    }
  }, [AddFilesSuccess, reset, setReFetch]);

  const onSubmit = (data) => {
    let finalData = { file_name: data.file_name[0] };
    dispatch({
      type: "setSubmitAddEditMaterial",
      payload: {
        flag: "AddEditMaterial",
        dependency: !state.submitAddFiles.dependency,
        data: finalData,
      },
    });
  };

  return (
    <form
      encType="multipart/form-data"
      method="post"
      onSubmit={handleSubmit(onSubmit)}
      className="flex h-fit w-1/2 mx-auto flex-col  items-center justify-start gap-6 rounded-3xl   p-10 shadow-lg shadow-red-200/30 md:w-full "
    >
      {/** file is-public filed */}
      <div className="flex w-full items-end justify-between gap-16 md:flex-col md:gap-10 ">
        {/** file  */}
        <div className="flex flex-col items-end justify-center gap-2 w-full">
          <label className="w-full " htmlFor="file_name">
            File{" "}
          </label>

          <input
            id="file_name"
            className="signin-inputs signin-input w-full"
            type="file"
            accept=".png,.jpeg,.jpg,.pdf"
            name="file_name"
            {...register("file_name", {
              required: true,
            })}
          />

          {errors.file_name && (
            <p className="mt-2 w-full text-end text-[12px] text-red-900">
              {errors.file_name.type === "required" && "Please Add File"}
              {errors.file_name.type === "validate" && "maximum size is 15 MB"}
            </p>
          )}
          {
            //!-------server errors -----

            AddFilesErrors &&
              AddFilesErrors?.response?.data?.errors?.address && (
                <p className="w-full text-end text-[12px] text-red-900  ">
                  {AddFilesErrors?.response?.data?.errors?.address[0]}
                </p>
              )
          }
        </div>
      </div>

      {/** submit */}

      <button
        disabled={!isValid || (submitLoading && state.submitAddFiles.flag)}
        className=" submit mt-6 w-full "
        type="submit"
      >
        {submitLoading && state.submitAddFiles.flag ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          <p>Add File</p>
        )}
      </button>
    </form>
  );
};
