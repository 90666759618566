import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import SingleCourse from "../../../../MainComponents/SingleCourse";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../../MainComponents/Loader.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import {
  DndContext,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

const SortableItem = ({ id, children }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transition: transition || undefined,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </div>
  );
};

function AdminChapter() {
  const { chapterID } = useParams();
  const AuthState = useAuthState();
  const [reFetch, setReFetch] = useState(false);
  const [lectures, setLectures] = useState([]);
  const [submitPirority, setSubmitPirority] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [ChapterInfo, ChapterInfoErrors, ChapterInfoLoading] = useAxios(
    `${process.env.REACT_APP_ADMIN_CHAPTER_INFO_API}${chapterID}`,
    "GET",
    chapterID,
    chapterID
  );

  //!---- get chapter info -------
  const [chapterInfoSuccess, chapterInfoErrors, chapterInfoLoading] = useAxios(
    `${process.env.REACT_APP_ADMIN_CHAPTER_PAGE_API}${chapterID}`,
    "GET",
    chapterID,
    reFetch || chapterID
  );

  const [prioritySuccess, priorityErrors, priorityloading] = useAxios(
    process.env.REACT_APP_ADMIN_LECTURES_EDIT_PIRORITY_API,
    "POST",
    submitPirority.flag,
    submitPirority.dependency,
    submitPirority.data,
    true
  );

  useEffect(() => {
    if (chapterInfoSuccess?.data) {
      setLectures(chapterInfoSuccess.data);
    }
  }, [chapterInfoSuccess]);

  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      distance: 10,
    },
  });
  const touchSensor = useSensor(TouchSensor, {
    // Press delay of 250ms, with tolerance of 5px of movement
    activationConstraint: {
      delay: 250,
      tolerance: 5,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor);
  useEffect(() => {
    if (prioritySuccess) {
      setSubmitPirority({
        flag: "",
        dependency: false,
        data: {},
      });
    }
  }, [prioritySuccess]);

  const updatePriorities = (updatedLectures) => {
    setSubmitPirority({
      flag: "priority",
      dependency: !submitPirority.dependency,
      data: {
        lectures: updatedLectures.map((lecture) => ({
          id: lecture.key,
          priority_num: lecture.priority_num,
        })),
      },
    });
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (!active || !over) return; // تأكد إنهم مش undefined

    if (active.id !== over.id) {
      setLectures((lectures) => {
        const clonedLectures = [...lectures]; // تأكد إنك مش بتعدل على الأصل
        const oldIndex = clonedLectures.findIndex(
          (lecture) => lecture.key === active.id
        );
        const newIndex = clonedLectures.findIndex(
          (lecture) => lecture.key === over.id
        );

        if (oldIndex === -1 || newIndex === -1) return clonedLectures;

        const newLectures = arrayMove(clonedLectures, oldIndex, newIndex);

        const updatedLectures = newLectures.map((lecture, index) => ({
          ...lecture,
          priority_num: index + 1,
        }));

        updatePriorities(updatedLectures);

        return updatedLectures;
      });
    }
  };

  if (ChapterInfoLoading || chapterInfoLoading) return <Loader />;
  return (
    <section
      className={`  h-auto  w-full  px-20 py-20  dark:bg-dark dark:text-light `}
    >
      <HelmetTags title="Chapters | Mr.Ahmed Sabry"></HelmetTags>
      <div className="mb-20 flex flex-col items-start gap-20">
        <h2 className="flex-1 text-4xl text-left font-bold md:text-center">
          {ChapterInfo?.data?.name}
        </h2>
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some(
            (permission) => permission.name === "lecture_add"
          )) && (
          <Link
            to={`/admin/lectures/${chapterID}/add-lecture`}
            className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 "
          >
            <FontAwesomeIcon className="mr-4" icon={faPlus} />
            Add lecture
          </Link>
        )}
      </div>
      {chapterInfoLoading ? (
        <Loader />
      ) : (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={lectures.map((lecture) => lecture.key)}
            strategy={verticalListSortingStrategy}
          >
            <div className="lectures grid-auto-fit">
              {lectures.length === 0 ? (
                <p>There are no lectures for this class</p>
              ) : (
                lectures.map((lecture) => (
                  <SortableItem key={lecture.key} id={lecture.key}>
                    <SingleCourse
                      lecture={lecture}
                      admin
                      setReFetch={setReFetch}
                      reFetch={reFetch}
                    />
                  </SortableItem>
                ))
              )}
            </div>
          </SortableContext>
        </DndContext>
      )}
    </section>
  );
}

export default AdminChapter;
