import React, { useState } from "react";
import HelmetTags from "../../../../MainComponents/HelmetTags";

import { useAuthState } from "../../../../MainComponents/GlobalContext";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { Loader } from "../../../../MainComponents";
import {  AdminLandingUpdate } from "./AdminLandingAddImage";
import { AdminViewAllLandingFiles } from "./AdminViewAllLandingFiles";

const Landing = () => {
  const AuthState = useAuthState();
  const [reFetch, setReFetch] = useState(false);

    const [LandingData, LandingErrors, Landingloading] = useAxios(
    process.env.REACT_APP_LANDING_API,
    "GET",
    "GET",
    reFetch
  ); 



  return (
    <section className="h-auto w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="Landing Page |  Mr Ahmed Sabry"></HelmetTags>{" "}
      <h2 className="w-full text-4xl font-bold md:text-center">Landing Page</h2>
      {(AuthState.userData?.admin?.roles[0]?.name === "superadmin") && (
        <AdminLandingUpdate
          setReFetch={setReFetch}
          LandingData={LandingData}
        />
      )}
    </section>
  );
};

export default Landing;
