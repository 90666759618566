import { Link, useParams } from "react-router-dom";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useState } from "react";
import Loader from "../../../../MainComponents/Loader";
import VideoCard from "../../../../MainComponents/VideoCard";
import HelmetTags from "../../../../MainComponents/HelmetTags";

function Videos() {
  const AuthState = useAuthState();
  const { lectureID } = useParams();
  const [reFetch, setReFetch] = useState(false);
  const [showVideo, setShowVideo] = useState({
    show: false,
    src: "",
  });

  //!---- get all videos -------
  const [videosSuccess, videosErrors, videosLoading] = useAxios(
    `${process.env.REACT_APP_ADMIN_LECTURE_VIDEOS_API}${lectureID}`,
    "GET",
    lectureID,
    reFetch || lectureID
  );

  const handleCloseVideo = () => {
    setShowVideo({
      show: false,
      src: "",
    });
  };

  return (
    <section
      className={`h-auto w-full px-20 py-20 dark:bg-dark dark:text-light`}
    >
      <HelmetTags title="Videos | Mr.Ahmed Sabry"></HelmetTags>

      {/* Overlay for Video Player */}
      <div
        onClick={handleCloseVideo}
        className={`absolute inset-0 z-50 flex h-screen w-full origin-bottom items-center justify-center bg-blue-200/40 transition-all duration-300 ease-in-out ${
          showVideo.show
            ? "opacity-100 pointer-events-auto scale-100"
            : "opacity-0 pointer-events-none scale-0"
        }`}
      >
        <div className="iframe-wrapper aspect-video w-3/4 overflow-hidden rounded-3xl border-0 border-secondary">
          {showVideo.src && (
            <iframe
              src={showVideo.src}
              width="100%"
              height="100%"
              title="Video Player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          )}
        </div>
      </div>

      <div className="mb-20 flex flex-col items-start gap-20">
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("lecture_addvideos")
          )) && (
          <Link
            to={`/admin/lectures/${lectureID}/add-video`}
            className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary active:scale-90"
          >
            <FontAwesomeIcon className="mr-4" icon={faPlus} />
            Add Video
          </Link>
        )}

        {videosLoading ? (
          <Loader />
        ) : (
          <div className="videos video-grid-auto-fit w-full">
            {videosSuccess?.data?.length === 0 ? (
              <p>No Videos For This Lecture</p>
            ) : (
              videosSuccess?.data?.map((video) => (
                <VideoCard
                  reFetch={reFetch}
                  setReFetch={setReFetch}
                  setShowVideo={setShowVideo}
                  admin
                  video={video}
                  key={video.key}
                />
              ))
            )}
          </div>
        )}
      </div>
    </section>
  );
}

export default Videos;
