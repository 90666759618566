import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import useAxios from "../../../../MainComponents/Hooks/useAxios";

export const AdminLandingUpdate = ({ setReFetch, LandingData }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    setValue,
  } = useForm({ mode: "onTouched" });

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddEditMaterial": {
        return {
          ...state,
          submitAddFiles: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddFiles: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  //!--------- add edit Material -------

  const [AddFilesSuccess, AddFilesErrors, submitLoading] = useAxios(
    process.env.REACT_APP_LANDING_EDIT_API,
    "POST",
    state.submitAddFiles.flag,
    state.submitAddFiles.dependency,
    state.submitAddFiles.data,
    true
  );

  //!--------- get the Material info for editing -------

  useEffect(() => {
    //!---Add actions ----
    if (AddFilesSuccess) {
      reset();
      setReFetch((refetch) => !refetch);
    }
  }, [AddFilesSuccess, reset, setReFetch]);

  useEffect(() => {
    if (LandingData) {
      let temp = LandingData?.data[0];
      setValue("title", temp?.title);
      setValue("description", temp?.description);
      setValue("description_about", temp?.description_about);
      setValue("link", temp?.link);
      setValue("link2", temp?.link2);
      setValue("link3", temp?.link3);
      setValue("link4", temp?.link4);
      setValue("link5", temp?.link5);
    }
  }, [LandingData]);

  const onSubmit = (data) => {
    let finalData = {
      key: 1,
      title: data.title,
      description: data.description,
      description_about: data.description_about,
      link: data.link,
      link2: data.link2,
      link3: data.link3,
      link4: data.link4,
      link5: data.link5,
      image: data.image[0],
      image2: data.image2[0],
    };
    dispatch({
      type: "setSubmitAddEditMaterial",
      payload: {
        flag: "AddEditMaterial",
        dependency: !state.submitAddFiles.dependency,
        data: finalData,
      },
    });
  };

  return (
    <form
      encType="multipart/form-data"
      method="post"
      onSubmit={handleSubmit(onSubmit)}
      className="flex h-fit w-1/2 mx-auto flex-col  items-center justify-start gap-6 rounded-3xl   p-10 shadow-lg shadow-red-400/30 md:w-full "
    >
      <div className="flex w-full items-end justify-between gap-16 flex-col md:gap-10 ">
        {/** file  */}
        <div className="flex flex-col items-end justify-center gap-2 w-full">
          <label className="w-full " htmlFor="title">
            Title
          </label>

          <input
            id="title"
            className="signin-inputs signin-input w-full"
            type="text"
            name="title"
            {...register("title", {
              required: true,
            })}
          />

          {errors.title && (
            <p className="mt-2 w-full text-end text-[12px] text-red-900">
              {errors.title.type === "required" && "Please Add File"}
            </p>
          )}
          {
            //!-------server errors -----

            AddFilesErrors &&
              AddFilesErrors?.response?.data?.errors?.address && (
                <p className="w-full text-end text-[12px] text-red-900  ">
                  {AddFilesErrors?.response?.data?.errors?.address[0]}
                </p>
              )
          }
        </div>
        {/** file  */}
        <div className="flex flex-col items-end justify-center gap-2 w-full">
          <label className="w-full " htmlFor="description">
            Description
          </label>

          <textarea
            id="description"
            className="signin-inputs signin-input w-full"
            type="text"
            name="description"
            {...register("description", {
              required: true,
            })}
          ></textarea>

          {errors.description && (
            <p className="mt-2 w-full text-end text-[12px] text-red-900">
              {errors.description.type === "required" &&
                "Please Add Description"}
            </p>
          )}
          {
            //!-------server errors -----

            AddFilesErrors &&
              AddFilesErrors?.response?.data?.errors?.address && (
                <p className="w-full text-end text-[12px] text-red-900  ">
                  {AddFilesErrors?.response?.data?.errors?.address[0]}
                </p>
              )
          }
        </div>
      </div>
      <div className="flex w-full items-end justify-between gap-16 flex-col md:gap-10 ">
        {/** file  */}
        <div className="flex flex-col items-end justify-center gap-2 w-full">
          <label className="w-full " htmlFor="title">
            Description About
          </label>
          <textarea
            id="description_about"
            className="signin-inputs signin-input w-full"
            type="text"
            name="description_about"
            {...register("description_about", {
              required: true,
            })}
          ></textarea>

          {errors.description_about && (
            <p className="mt-2 w-full text-end text-[12px] text-red-900">
              {errors.description_about.type === "required" &&
                "Please Add Description About"}
            </p>
          )}
          {
            //!-------server errors -----

            AddFilesErrors &&
              AddFilesErrors?.response?.data?.errors?.address && (
                <p className="w-full text-end text-[12px] text-red-900  ">
                  {AddFilesErrors?.response?.data?.errors?.address[0]}
                </p>
              )
          }
        </div>
        {/** file  */}
        <div className="flex flex-col items-end justify-center gap-2 w-full">
          <label className="w-full " htmlFor="link">
            Link
          </label>

          <input
            id="Link"
            className="signin-inputs signin-input w-full"
            type="text"
            name="Link"
            {...register("link", {
              required: true,
            })}
          />

          {errors.link && (
            <p className="mt-2 w-full text-end text-[12px] text-red-900">
              {errors.link.type === "required" && "Please Add Link"}
            </p>
          )}
          {
            //!-------server errors -----

            AddFilesErrors &&
              AddFilesErrors?.response?.data?.errors?.address && (
                <p className="w-full text-end text-[12px] text-red-900  ">
                  {AddFilesErrors?.response?.data?.errors?.address[0]}
                </p>
              )
          }
        </div>
        <div className="flex flex-col items-end justify-center gap-2 w-full">
          <label className="w-full " htmlFor="link">
            Facebook
          </label>

          <input
            id="Link"
            className="signin-inputs signin-input w-full"
            type="text"
            name="Link"
            {...register("link2", {
              required: true,
            })}
          />

          {errors.link2 && (
            <p className="mt-2 w-full text-end text-[12px] text-red-900">
              {errors.link2.type === "required" && "Please Add Link"}
            </p>
          )}
          {
            //!-------server errors -----

            AddFilesErrors && AddFilesErrors?.response?.data?.errors?.link2 && (
              <p className="w-full text-end text-[12px] text-red-900  ">
                {AddFilesErrors?.response?.data?.errors?.link2[0]}
              </p>
            )
          }
        </div>
        <div className="flex flex-col items-end justify-center gap-2 w-full">
          <label className="w-full " htmlFor="link">
            Instagram
          </label>

          <input
            id="Link"
            className="signin-inputs signin-input w-full"
            type="text"
            name="Link"
            {...register("link3", {
              required: true,
            })}
          />

          {errors.link3 && (
            <p className="mt-2 w-full text-end text-[12px] text-red-900">
              {errors.link3.type === "required" && "Please Add Link"}
            </p>
          )}
          {
            //!-------server errors -----

            AddFilesErrors &&
              AddFilesErrors?.response?.data?.errors?.address && (
                <p className="w-full text-end text-[12px] text-red-900  ">
                  {AddFilesErrors?.response?.data?.errors?.address[0]}
                </p>
              )
          }
        </div>
        <div className="flex flex-col items-end justify-center gap-2 w-full">
          <label className="w-full " htmlFor="link">
            Tiktok
          </label>

          <input
            id="Link"
            className="signin-inputs signin-input w-full"
            type="text"
            name="Link"
            {...register("link4", {
              required: true,
            })}
          />

          {errors.link && (
            <p className="mt-2 w-full text-end text-[12px] text-red-900">
              {errors.link.type === "required" && "Please Add Link"}
            </p>
          )}
          {
            //!-------server errors -----

            AddFilesErrors &&
              AddFilesErrors?.response?.data?.errors?.address && (
                <p className="w-full text-end text-[12px] text-red-900  ">
                  {AddFilesErrors?.response?.data?.errors?.address[0]}
                </p>
              )
          }
        </div>
        <div className="flex flex-col items-end justify-center gap-2 w-full">
          <label className="w-full " htmlFor="link">
            Whatsapp
          </label>

          <input
            id="Link"
            className="signin-inputs signin-input w-full"
            type="text"
            name="Link"
            {...register("link5", {
              required: true,
            })}
          />

          {errors.link && (
            <p className="mt-2 w-full text-end text-[12px] text-red-900">
              {errors.link.type === "required" && "Please Add Link"}
            </p>
          )}
          {
            //!-------server errors -----

            AddFilesErrors &&
              AddFilesErrors?.response?.data?.errors?.address && (
                <p className="w-full text-end text-[12px] text-red-900  ">
                  {AddFilesErrors?.response?.data?.errors?.address[0]}
                </p>
              )
          }
        </div>
      </div>
      {/** file is-public filed */}
      <div className="flex w-full items-end justify-between gap-16 flex-col md:gap-10 ">
        {/** file  */}
        <div className="flex flex-col items-end justify-center gap-2 w-full">
          <label className="w-full " htmlFor="image1">
            Image 1{" "}
          </label>

          <input
            id="image1"
            className="signin-inputs signin-input w-full"
            type="file"
            accept=".png,.jpeg,.jpg,.pdf"
            name="image1"
            {...register("image")}
          />

          {errors.image && (
            <p className="mt-2 w-full text-end text-[12px] text-red-900">
              {errors.image.type === "required" && "Please Add File"}
              {errors.image.type === "validate" && "maximum size is 15 MB"}
            </p>
          )}
          {
            //!-------server errors -----

            AddFilesErrors &&
              AddFilesErrors?.response?.data?.errors?.address && (
                <p className="w-full text-end text-[12px] text-red-900  ">
                  {AddFilesErrors?.response?.data?.errors?.address[0]}
                </p>
              )
          }
        </div>
        {/** file  */}
        <div className="flex flex-col items-end justify-center gap-2 w-full">
          <label className="w-full " htmlFor="image2">
            Image 2{" "}
          </label>

          <input
            id="image2"
            className="signin-inputs signin-input w-full"
            type="file"
            accept=".png,.jpeg,.jpg,.pdf"
            name="image2"
            {...register("image2")}
          />

          {errors.image2 && (
            <p className="mt-2 w-full text-end text-[12px] text-red-900">
              {errors.image2.type === "required" && "Please Add File"}
              {errors.image2.type === "validate" && "maximum size is 15 MB"}
            </p>
          )}
          {
            //!-------server errors -----

            AddFilesErrors &&
              AddFilesErrors?.response?.data?.errors?.address && (
                <p className="w-full text-end text-[12px] text-red-900  ">
                  {AddFilesErrors?.response?.data?.errors?.address[0]}
                </p>
              )
          }
        </div>
      </div>
      {/** submit */}

      <button
        disabled={submitLoading && state.submitAddFiles.flag}
        className=" submit mt-6 w-full "
        type="submit"
      >
        {submitLoading && state.submitAddFiles.flag ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          <p>Edit LandingPage</p>
        )}
      </button>
    </form>
  );
};
