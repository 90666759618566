import useTable from "../../../../MainComponents/Hooks/useTable.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import { set, useForm } from "react-hook-form";

function Students({ type }) {
  const [academiclevel, setAcademiclevel] = useState(null);
  const [stage, setStage] = useState(22);
  const [academicType, setAcademicType] = useState(null);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [submitAdd, setSubmitAdd] = useState({
    flag: "",
    depedency: false,
    data: {},
  });
  const { handleSubmit } = useForm();
  const onSubmit = () => {
    setSubmitAdd({
      flag: "addStudentsRequest",
      depedency: !submitAdd.dependency,
      data: {
        ids: selectedStudents.map((student) => student.key),
        stage: stage,
      },
    });
  };
  const [sucessStage, errorStage, loadingStage] = useAxios(
    process.env.REACT_APP_ADMIN_STUDENTS_UPDATE_STAGES_API,
    "POST",
    submitAdd.flag,
    submitAdd.depedency,
    submitAdd.data,
    true
  );

  // Restore state from localStorage on component mount
  useEffect(() => {
    const savedType = localStorage.getItem("academicType");
    const savedLevel = localStorage.getItem("academicLevel");

    if (savedType) setAcademicType(savedType);
    if (savedLevel) setAcademiclevel(savedLevel);
  }, []);

  useEffect(() => {
    if (sucessStage) {
      setRefetch(!refetch);
      setSelectedStudents([]);
      setSubmitAdd({ flag: "", depedency: false, data: {} });
    }
  }, [sucessStage]);
  // Save state to localStorage whenever it changes
  useEffect(() => {
    if (academicType) localStorage.setItem("academicType", academicType);
    if (academiclevel) localStorage.setItem("academicLevel", academiclevel);
  }, [academicType, academiclevel]);
  const url = `${academiclevel}/${type === "online" ? "1" : "0"}`;
  const tableFor = type === "online" ? "onlineStudents" : "centerStudents";
  useEffect(() => {
    if (url) {
      setSelectedStudents([]);
    }
  }, [url]);
  const table = useTable(
    academicType && academiclevel ? tableFor : null,
    "",
    "",
    url,
    refetch,
    url,
    "",
    "",
    "",
    "",
    setSelectedStudents,
    selectedStudents
  );

  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20  dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags
        title={`${
          type === "online" ? "Online" : "Center"
        } Students | Mr Ahmed Sabry`}
      ></HelmetTags>
      <div className="mb-20 flex flex-col items-end gap-20">
        <div className="w-full flex items-center justify-between gap-4">
          <h2 className="flex-1 text-4xl text-left font-bold md:text-center">
            {type === "online" ? "Online" : "Center"} Students
          </h2>
          <Link
            to={`/admin/${
              type === "online" ? "online" : "center"
            }-students/add-student`}
            className={`rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 bg-secondary text-light flex items-center gap-6`}
          >
            <FontAwesomeIcon icon={faPlus} />
            Add Student
          </Link>
        </div>

        <div className="flex w-full  gap-10 md:justify-center sm:flex-col-reverse sm:items-center">
          <button
            onClick={() => {
              setAcademicType("national");
              setAcademiclevel("");
            }}
            className={`min-w-[200px] rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
              academicType === "national" && "bg-secondary text-light"
            }`}
          >
            National
          </button>

          <button
            onClick={() => {
              setAcademicType("ig");
              setAcademiclevel("");
            }}
            className={`min-w-[200px] rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
              academicType === "ig" && "bg-secondary text-light"
            }`}
          >
            IG
          </button>
        </div>
        {academicType === "national" && (
          <div className="flex w-full  gap-10 md:justify-center md:flex-col sm:items-center">
            <button
              onClick={() => {
                setAcademiclevel("22");
              }}
              className={` rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
                academiclevel === "22" && "bg-secondary text-light"
              }`}
            >
              Second preparatory
            </button>
            <button
              onClick={() => {
                setAcademiclevel("33");
              }}
              className={` rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
                academiclevel === "33" && "bg-secondary text-light"
              }`}
            >
              Third Preparatory
            </button>

            <button
              onClick={() => {
                setAcademiclevel("1");
              }}
              className={` rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
                academiclevel === "1" && "bg-secondary text-light"
              }`}
            >
              First Secondary
            </button>

            <button
              onClick={() => {
                setAcademiclevel("2");
              }}
              className={` rounded-xl border-2 border-secondary    p-4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
                academiclevel === "2" && "bg-secondary text-light"
              }`}
            >
              Second Secondary
            </button>

            <button
              onClick={() => {
                setAcademiclevel("3");
              }}
              className={` rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
                academiclevel === "3" && "bg-secondary text-light"
              }`}
            >
              Third Secondary
            </button>
          </div>
        )}
        {academicType === "ig" && (
          <div className="flex w-full  gap-10 md:justify-center  md:flex-col sm:items-center">
            <button
              onClick={() => {
                setAcademiclevel("9");
              }}
              className={` rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
                academiclevel === "9" && "bg-secondary text-light"
              }`}
            >
              Nine Year
            </button>
            <button
              onClick={() => {
                setAcademiclevel("10");
              }}
              className={` rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
                academiclevel === "10" && "bg-secondary text-light"
              }`}
            >
              Ten Year
            </button>
          </div>
        )}
      </div>
      {selectedStudents?.length > 0 ? (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex h-fit shadow-3xl w-full max-w-[600px] mx-auto rounded-2xl flex-col items-center justify-center gap-24 py-10 dark:bg-dark sm:py-14"
        >
          <select
            className="signin-inputs max-w-[500px]"
            placeholder="stage"
            onChange={(e) => setStage(e.target.value)}
          >
            <option value="22">Second prep.</option>
            <option value="33">Third prep.</option>
            <option value="1">First sec.</option>
            <option value="2">Second sec.</option>
            <option value="3">Third sec.</option>
          </select>
          <button className=" rounded-2xl border-2 border-accent bg-accent py-1 px-3 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90">
            Change Stage
          </button>
        </form>
      ) : null}
      {/**
       * //!---------Table-----
       */}
      {academiclevel && academicType ? (
        <div className="h-fit  w-full">{table}</div>
      ) : null}
    </section>
  );
}

export default Students;
